import './App.css'
import { ChakraProvider } from '@chakra-ui/react'
import { SignUpPage } from './SignUpPage'


function App() {
  return (
    <ChakraProvider>
      <SignUpPage></SignUpPage>
    </ChakraProvider>
  );
}

export default App;
