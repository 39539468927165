import shark from './/beer-shark.webp'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc,query, where, getDocs } from 'firebase/firestore/lite';
import { Flex,Box,Text,Input, Button,useToast,useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';

const firebaseConfig = {
  apiKey: "AIzaSyD_OC5kS6f35k9S5F34NgmDMbOKeWqDPKE",
  authDomain: "beershark-79cc0.firebaseapp.com",
  projectId: "beershark-79cc0",
  storageBucket: "beershark-79cc0.appspot.com",
  messagingSenderId: "240062738525",
  appId: "1:240062738525:web:19d50b87407ba30bac9db4",
  measurementId: "G-9FX3CZZY1K"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export function SignUpPage() {
    const toast = useToast()
    const [isMobile] = useMediaQuery("(max-width: 768px)") 
    const [email,setEmail] =useState('')
    async function submitEmail(email) {
      const q = query(collection(db, "sign-ups"), where("email", "==", email))
      const querySnapshot = await getDocs(q)
      console.log(querySnapshot.docs.length)
      if (querySnapshot.docs.length < 1 ){
        try {
            if (email.includes('@')){
            const docRef = await addDoc(collection(db, "sign-ups"), {
                email: email
            });
            toast({
                position: 'top-right',
                title: 'Sign Up Successful',
                description: "We'll Let You Know When BeerShark is Ready",
                status: 'success',
                duration: 9000,
                isClosable: true,
              })
            console.log("Document written with ID: ", docRef.id);}
            else { toast({
              position: 'top-right',
              title: "Oops! That wasn't an Email Address",
              description: "Type in an Email Address",
              status: 'error',
              duration: 9000,
              isClosable: true,
            })}
            } catch (e) { 
            console.error("Error adding document: ", e);
            }}
      else {
        toast({
          position: 'top-right',
          title: "Looks Like You're Already on our Waitlist!",
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    }


    return(
    <div className="App">
      <header className="App-header">
        <img src={shark} className="App-logo" alt="logo" />
        <p justify={'center'}>
          BeerShark Coming Soon
        </p>
        {isMobile ? <Flex  h='10%' w="95%" direction={'column'} mt='2%'> 
        <Box color={'black'} background={'white'} borderWidth='1px' borderRadius='lg'>
        <Text>Join The Wait List</Text>
        <Input placeholder='email@email.com' w='80%' alig={'left'} onChange={(e)=>{setEmail(e.target.value)}}></Input>
        <Button margin='10px' w='40%' onClick={() => submitEmail(email)}>Prepare For Beer!</Button>
        </Box>
    </Flex> : 
        
        <Flex  h='10%' w="30%" direction={'column'} mt='2%'> 
        <Box color={'black'} background={'white'} borderWidth='1px' borderRadius='lg'>
        <Text>Join The Wait List</Text>
        <Input placeholder='email@email.com' w='80%' alig={'left'} onChange={(e)=>{setEmail(e.target.value)}}></Input>
        <Button margin='10px' w='40%' onClick={() => submitEmail(email)}>Prepare For Beer!</Button>
        </Box>
    </Flex>
        }
      </header>
      
    </div>)
}